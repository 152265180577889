import { Ref, ref, watch } from 'vue'
import { Autosuggestion, AutosuggestionItem } from '@/Pages/the-home/interfaces/Search'
import { useDebounceFn } from '@vueuse/core'
import axios from 'axios'
import { useLoader } from '@/Composables/loader'
import { Location } from '@/Pages/the-home/interfaces/Search'
type UseSearch = {
    search: Ref<string>
    isOpened: Ref<boolean>
    query: Ref<string>
    isSearchInputValid: Ref<boolean>
    handleSearchInputClick: () => Promise<void>
    selectPlace: (place: AutosuggestionItem) => void
    setInitialData: (location: Location | undefined) => void
    autosuggestionResults: Ref<Autosuggestion[]>
    autosuggestionResultsVisible: Ref<boolean>
    url: Ref<string>
    isLoadingSuggestions: Ref<boolean>
    getSuggestions: (search?: string) => Promise<void>
    getFirstSearchQuery: () => void
}

export function useLocationSearch (): UseSearch {
    const isOpened: Ref<boolean> = ref(false)
    const search: Ref<string> = ref('')
    const query: Ref<string> = ref('')
    const url: Ref<string> = ref('')
    const autosuggestionResults: Ref<Autosuggestion[]> = ref([])
    const autosuggestionResultsVisible = ref(false)
    const isSearchInputValid = ref(true)
    const isPlaceSelected = ref(true)

    const { load, isLoading: isLoadingSuggestions } = useLoader()

    watch(search, async (newSearch: string, oldSearch: string) => {
        autosuggestionResultsVisible.value = true

        if (!isSearchInputValid.value) {
            isSearchInputValid.value = true
        }

        if (newSearch.toLowerCase() === oldSearch.toLowerCase()) {
            return
        }

        if (isPlaceSelected.value && !isOpened.value) {
            isPlaceSelected.value = false
            return
        }

        await debouncedSuggestionsRequest(newSearch)

        if (!autosuggestionResults.value.length) {
            autosuggestionResultsVisible.value = false
        }
    })

    function setInitialData (location: Location | undefined): void {
        search.value = location?.search || ''
        query.value = location?.search || ''
        url.value = location?.url || ''
    }

    async function handleSearchInputClick (): Promise<void> {
        if (!isOpened.value) {
            await getSuggestions()
            isOpened.value = true
            autosuggestionResultsVisible.value = true
            return
        }

        if (!autosuggestionResults.value.length) {
            return
        }

        autosuggestionResultsVisible.value = true
    }

    function selectPlace (place: AutosuggestionItem): void {
        autosuggestionResultsVisible.value = false
        isPlaceSelected.value = true

        search.value = place.query
        query.value = place.query
        url.value = place.url
    }

    const debouncedSuggestionsRequest = useDebounceFn((search: string) => getSuggestions(search), 300)

    async function getSuggestions (search: string = ''): Promise<void> {
        return load(async () => {
            const { data } = await axios.get('/search-autocomplete', {
                params: {
                    s: search
                }
            })

            autosuggestionResults.value = data.suggestions
        })
    }

    const getFirstSearchQuery = (): void => {
        if (autosuggestionResults.value.length > 0 && autosuggestionResults.value[0].items.length > 0) {
            selectPlace(autosuggestionResults.value[0].items[0])
        }
    }

    return {
        isOpened,
        search,
        query,
        url,
        isLoadingSuggestions,
        autosuggestionResults,
        autosuggestionResultsVisible,
        isSearchInputValid,
        handleSearchInputClick,
        selectPlace,
        setInitialData,
        getSuggestions,
        getFirstSearchQuery
    }
}