import { Ref, ref } from 'vue'

type callbackFunction = () => Promise<void>
type errorHandler = (e: unknown) => void

type useLoader = {
    load: (fn: callbackFunction, errorHandler?: errorHandler) => Promise<void>
    isLoading: Ref<boolean>
}


export function useLoader (): useLoader {
    const isLoading = ref(false)

    async function load (fn: callbackFunction, errorHandler?: errorHandler): Promise<void> {
        try {
            isLoading.value = true
            return await fn()
        } catch (e: unknown) {
            errorHandler?.(e)
        } finally {
            isLoading.value = false
        }
    }

    return {
        load,
        isLoading
    }
}